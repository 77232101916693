import React from 'react';
import { graphql } from 'gatsby';
import Image from 'gatsby-image';

import Layout from '../components/Layout';
import SEO from '../components/SEO';

class Post extends React.Component {
  render() {
    const { post, media } = this.props.data;

    // Generate gallery elements
    let mediaElements = [];
    // Cover image goes first
    mediaElements.push(
      <Image
        key={0}
        fluid={
          media.edges.filter(i => i.node.name === 'cover')[0].node
            .childImageSharp.fluid
        }
      />
    );
    // Rest of the media in ascending numerical order
    mediaElements.push(
      media.edges
        .filter(i => i.node.name !== 'cover')
        .map((media, i) => (
          media.node.childImageSharp
          ? <Image key={i + 1} fluid={media.node.childImageSharp.fluid} />
          : <video key={i + 1} src={media.node.publicURL} autoPlay loop controls playsInline />
        ))
    );

    // Generate video
    let video;
    if (post.frontmatter.youtube) {
      video = `<style>.embed-container { position: relative; padding-bottom: 56.25%; height: 0; overflow: hidden; max-width: 100%; } .embed-container iframe, .embed-container object, .embed-container embed { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }</style><div class='embed-container'><iframe src="https://www.youtube-nocookie.com/embed/${post.frontmatter.youtube}" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></div>`;
    }

    return (
      <Layout>
        <SEO title={post.frontmatter.title} />

        <div className="columns reverse-column-order post">
          <section className="column is-8">
            {mediaElements}

            <div dangerouslySetInnerHTML={{ __html: video }} />
          </section>

          <article className="column is-4">
            <div className="post-header">
              <h1>{post.frontmatter.title}</h1>
              <small>{post.frontmatter.tags}</small>
            </div>
            <div dangerouslySetInnerHTML={{ __html: post.html }} />
          </article>
        </div>
      </Layout>
    );
  }
}

export const query = graphql`
  query postQuery($slug: String!, $dirRegex: String!) {
    post: markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        date
        tags
        youtube
      }
    }
    media: allFile(
      filter: {
        dir: { regex: $dirRegex }
        extension: { in: ["jpg", "png", "gif", "mp4"] }
      }
      sort: { fields: name, order: ASC }
    ) {
      edges {
        node {
          name
          childImageSharp {
            fluid(maxWidth: 800, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
          publicURL
        }
      }
    }
  }
`;

export default Post;
